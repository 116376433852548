html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}
body {
  margin: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Prevent scrolling during loading */
}

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000; /* Background color for preloader */
  color: #fff; /* Text color */
  font-family: Arial, sans-serif;
  z-index: 9999; /* Ensure it stays on top */
}

.preloader h1 {
  font-size: 2rem;
  animation: fade 2s infinite;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}

main {
  display: block;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
html {
  box-sizing: border-box;
}
[hidden],
template {
  display: none;
}
*,
:after,
:before {
  box-sizing: inherit;
}
address {
  font-style: inherit;
}
cite,
dfn,
em,
i {
  font-style: italic;
}
b,
strong {
  font-weight: 700;
}
a {
  text-decoration: none;
}
a svg {
  pointer-events: none;
}
ol,
ul {
  list-style: none;
}
figure,
ol,
p,
ul {
  margin: 0;
  padding: 0;
}
[tabindex],
a,
area,
button,
input,
label,
select,
textarea {
  touch-action: manipulation;
}
[hreflang] > abbr[title] {
  text-decoration: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  border: 0;
  border-top: 1px solid #272727;
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
img,
svg {
  height: auto;
  max-width: 100%;
}
img[height],
img[width],
svg[height],
svg[width] {
  max-width: none;
}
img {
  font-style: italic;
}
svg {
  fill: currentColor;
}
input,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none transparent;
  border: 0;
  border-radius: 0;
  color: inherit;
  display: block;
  font: inherit;
  line-height: normal;
  margin: 0;
  outline: 0;
  padding: 0;
  width: 100%;
}
select {
  text-transform: none;
}
select::-ms-expand {
  display: none;
}
select::-ms-value {
  background: none;
  color: inherit;
}
textarea {
  overflow: auto;
  resize: vertical;
}
.o-button,
button {
  background: none transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  line-height: normal;
  margin: 0;
  outline: 0;
  overflow: visible;
  padding: 0;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
}
.o-button:focus,
.o-button:hover,
button:focus,
button:hover {
  text-decoration: none;
}

@media (max-width: 699px) {
  html {
    font-size: 12px;
  }
}
@media (min-width: 700px) and (max-width: 999px) {
  html {
    font-size: 13px;
  }
}
@media (min-width: 1000px) and (max-width: 1199px) {
  html {
    font-size: 14px;
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 1600px) and (max-width: 1999px) {
  html {
    font-size: 18px;
  }
}
@media (min-width: 2000px) and (max-width: 2399px) {
  html {
    font-size: 21px;
  }
}
@media (min-width: 2400px) {
  html {
    font-size: 24px;
  }
}
html:not(.has-scroll-init) {
  cursor: wait;
  overflow: hidden;
}
::-moz-selection {
  background-color: #272727;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #272727;
  color: #fff;
  text-shadow: none;
}
a {
  color: #f1f1f1;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
html.has-scroll-smooth {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.has-scroll-smooth body {
  overflow: hidden;
}
.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}
[data-scroll-direction="horizontal"] [data-scroll-container] {
  display: inline-block;
  height: 100vh;
  white-space: nowrap;
}
[data-scroll-direction="horizontal"] [data-scroll-section] {
  display: inline-block;
  height: 100%;
  vertical-align: top;
  white-space: nowrap;
}
.c-scrollbar {
  height: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  width: 11px;
}
.c-scrollbar:hover {
  transform: scaleX(1.45);
}
.c-scrollbar:hover,
.has-scroll-dragging .c-scrollbar,
.has-scroll-scrolling .c-scrollbar {
  opacity: 1;
}
[data-scroll-direction="horizontal"] .c-scrollbar {
  bottom: 0;
  height: 10px;
  top: auto;
  transform: scaleY(1);
  width: 100%;
}
[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}
.c-scrollbar_thumb {
  background-color: #ffffff;
  border-radius: 10px;
  cursor: -webkit-grab;
  cursor: grab;
  margin: 2px;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  width: 7px;
}
.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  bottom: 0;
  right: auto;
}
.c-header {
  position: relative;
  z-index: 10;
}
@media (max-width: 699px) {
  .c-header {
    height: 50vh;
    min-height: 340px;
  }
}
@media (min-width: 700px) and (max-width: 999px) {
  .c-header {
    height: 80vh;
    min-height: 380px;
  }
}
@media (min-width: 1000px) {
  .c-header {
    height: 100vh;
    min-height: 46.875rem;
  }
}
.c-header_title {
  padding: 10rem 0;
  perspective: 100px;
  -webkit-perspective: 600px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.c-header_line,
.c-header_title {
  bottom: 0;
  left: 0;
  margin: 0;
  position: absolute;
}
.c-header_line {
  right: 0;
  transform: scaleX(0);
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.c-header_line.is-inview {
  transform: scaleX(1);
}
.c-header_title_line {
  display: block;
  opacity: 0;
  transform: translateY(100%) rotateX(-80deg);
  transform-origin: center top;
  transform-style: preserve-3d;
  transition: opacity 0s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 0s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.c-header_video_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: inset(15% 0); /* Crop 150px from top and bottom */
  z-index: 0;
  animation: slideInFromLeft 1.5s ease-in-out forwards; /* Animate from left */
  transform: translateX(-100%);
  opacity: 0;
}

html.is-ready .c-header_title_line {
  opacity: 1;
  transform: none;
  transition-duration: 2s;
}
html.is-ready .c-header_title_line:first-child {
  transition-delay: 2s;
}
html.is-ready .c-header_title_line:nth-child(2) {
  transition-delay: 3.5s;
}
html.is-ready .c-header_title_line:nth-child(3) {
  transition-delay: 0.3s;
}
html.is-ready .c-header_title_line:nth-child(4) {
  transition-delay: 0.4s;
}
.c-header_title_line span {
  display: inline-block;
}
.c-header_title_line.-version {
  font-size: 0;
  text-transform: none;
}
.c-header_heading {
  padding: 1.875rem 0;
}
.c-header_heading_label {
  display: block;
  transform: translateY(0px);
  transition: transform 0s cubic-bezier(0.215, 0.61, 0.355, 1);
}
html.is-ready .c-header_heading_label {
  transform: none;
  transition-duration: 0.6s;
}
html.is-ready .o-layout_item:nth-child(2) .c-header_heading_label {
  transition-delay: 0.1s;
}
.c-header_logo {
  bottom: 2.5rem;
  opacity: 1;
  position: absolute;
  right: 0;
  transform: translateY(120px);
  transition: opacity 0s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 0s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media (max-width: 699px) {
  .c-header_logo {
    height: 66px;
    width: 30px;
  }
}
@media (min-width: 700px) {
  .c-header_logo {
    height: 8.75rem;
    width: 4rem;
  }
}
.c-header_logo svg {
  display: block;
  height: 100%;
  width: 100%;
}
html.is-ready .c-header_logo {
  opacity: 1;
  transform: none;
  transition-delay: 0.6s;
  transition-duration: 0.6s;
}
.c-fixed_wrapper {
  background-color: #d4c9c9;
  overflow: auto;
  position: relative;
}
@media (min-width: 1000px) {
  .c-fixed_wrapper {
    height: 100vh;
  }
}
@media (max-width: 999px) {
  .c-fixed_wrapper {
    height: 50vh;
  }
}
.c-fixed_target {
  bottom: -100vh;
}
.c-fixed,
.c-fixed_target {
  left: 0;
  position: absolute;
  right: 0;
  top: -100vh;
}
.c-fixed {
  background-position: 50%;
  background-size: cover;
  height: 100%;
  mix-blend-mode: multiply;
  opacity: 0.75;
}
html:not(.has-scroll-smooth) .c-fixed {
  top: 0;
}
.c-intro {
  font-size: 2.1875rem;
  line-height: 1.2;
  margin: 7.5rem 0;
  opacity: 0;
  transform: translateY(60px);
  transition: opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.c-intro.is-inview {
  opacity: 1;
  transform: none;
  transition-delay: 0.3s;
}

.o-h,
.o-h1,
.o-h2,
.o-h3,
.o-h4,
.o-h5,
.o-h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 1.5;
  line-height: 1;
  margin-bottom: 0;
  margin-top: 0;
}
.o-h1,
h1 {
  line-height: 1.2;
  text-transform: uppercase;
}
@media (max-width: 699px) {
  .o-h1,
  h1 {
    font-size: 4vw;
  }
}
@media (min-width: 700px) and (max-width: 1599px) {
  .o-h1,
  h1 {
    font-size: 5vw;
  }
  .o-h1.-huge,
  h1.-huge {
    font-size: 5vh;
  }
}
@media (min-width: 1600px) {
  .o-h1,
  h1 {
    font-size: 5vw;
  }
}
.o-h2,
h2 {
  font-size: 2rem;
}
.o-h3,
h3 {
  font-size: 1.5rem;
}
.o-h4,
h4 {
  line-height: 1.2;
}
.o-h4,
.o-h5,
h4,
h5 {
  font-size: 1rem;
}
.o-h6,
h6 {
  font-size: 0.9375rem;
}
.o-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 95rem;
}
@media (max-width: 699px) {
  .o-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 700px) {
  .o-container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

/* Locomotive Scroll container */
[data-scroll-container] {
  overflow: auto;
  position: relative;
}

/* Static Far Background */
.far-background {
  position: fixed; /* Static global background */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: -2;
}

.o-scroll {
  background-color: #d4c9c9;
  overflow: auto;
}

/* Hero Section */
.hero-section {
  position: relative;
  height: 100vh;
  overflow: auto;
  z-index: 1;
}

/* Video Container with Cropping */
.elementor-background-video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: inset(150px 0); /* Crop 150px from top and bottom */
  z-index: 0;
  animation: slideInFromLeft 0.8s ease-in-out forwards; /* Animate from left */
  transform: translateX(-100%);
}

.dark-video {
  filter: brightness(100%);
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 0;
  transition: opacity 1s ease-in-out;
}

/* Hero Text */
.hero-text {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1;
  animation: slideUpText 1.5s ease-out 0.5s forwards; /* Slide-up animation */
  opacity: 0;
  white-space: nowrap; /* Prevent text wrapping */
}

.hero-text h1 {
  font-size: clamp(1rem, 3vw, 5rem); /* Responsive font size */
  margin: 0;
}

/* Highlighted Text */
.hero-text .highlight {
  color: #40e0d0; /* Turquoise */
  font-weight: bold; /* Optional: Make the highlight text bold */
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideUpText {
  0% {
    top: 100%;
    opacity: 0;
  }
  100% {
    top: 50%;
    opacity: 1;
  }
}

.summary_list {
  -webkit-text-size-adjust: 100%;
  color: #ffffff;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  line-height: 1.5;
  pointer-events: all;
  font-size: 1rem;
  box-sizing: inherit;
  list-style: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.summary_list_item {
  -webkit-text-size-adjust: 100%;
  color: #ffffff;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  line-height: 1.5;
  pointer-events: all;
  list-style: none;
  box-sizing: inherit;
  position: relative;
  transition: opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0;
  transform: translateX(50px);
  transition-delay: 0.1s;
  text-decoration: none;
}

.summary_list_item:after {
  background-color: #719797;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  transform: scaleX(0);
  transform-origin: center left;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.summary_list_item.is-inview {
  opacity: 1;
  transform: translateX(0px);
}

.summary_list_item.is-inview:after {
  transform: scaleX(1);
}

.summary_list_item.is-inview:first-child {
  transition-delay: 0.1s;
}

.summary_list_item.is-inview:first-child:after {
  transition-delay: 0.5s;
}

.summary_list_item.is-inview:nth-child(2) {
  transition-delay: 0.3s;
}

.summary_list_item.is-inview:nth-child(2):after {
  transition-delay: 0.6s;
}

.summary_list_item.is-inview:nth-child(3) {
  transition-delay: 0.5s;
}

.summary_list_item.is-inview:nth-child(3):after {
  transition-delay: 0.7s;
}

.summary_list_item.is-inview:nth-child(4) {
  transition-delay: 0.7s;
}

.summary_list_item.is-inview:nth-child(4):after {
  transition-delay: 0.8s;
}

.summary_list_item.is-inview:nth-child(5) {
  transition-delay: 0.9s;
}

.summary_list_item.is-inview:nth-child(5):after {
  transition-delay: 0.9s;
}

.summary_list_item.is-inview:nth-child(6) {
  transition-delay: 1.1s;
}

.summary_list_item.is-inview:nth-child(6):after {
  transition-delay: 1s;
}

.summary_list_item a {
  display: block;
  padding: 8px 0;
}

.summary_list_icon {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateX(30px) rotate(90deg);
  transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.summary_list_item.is-inview .summary_list_icon {
  opacity: 1;
  transform: translateY(-50%) rotate(0deg);
}

.summary_list_item.is-inview:first-child .summary_list_icon {
  transition-delay: 0.6s;
}

.summary_list_item.is-inview:nth-child(2) .summary_list_icon {
  transition-delay: 0.7s;
}

.summary_list_item.is-inview:nth-child(3) .summary_list_icon {
  transition-delay: 0.8s;
}

.summary_list_item.is-inview:nth-child(4) .summary_list_icon {
  transition-delay: 0.9s;
}

.summary_list_item.is-inview:nth-child(5) .summary_list_icon {
  transition-delay: 1s;
}

.summary_list_item.is-inview:nth-child(6) .summary_list_icon {
  transition-delay: 1.1s;
}

.c-summary {
  margin: 3.75rem 0 7.5rem;
}

.c-summary_text {
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4705882353;
  opacity: 0;
  transform: translateX(-200px);
  transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.c-summary.is-inview .c-summary_text {
  transform: translateX(0px);
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.c-section {
  position: relative;
}
@media (max-width: 1199px) {
  .c-section {
    margin-bottom: 35vh;
    padding-bottom: 10vh;
  }
}
@media (min-width: 1200px) {
  .c-section {
    margin-bottom: 35vh;
  }
}
.c-section_infos {
  max-width: 320px;
  position: relative;
  z-index: 0;
}
@media (min-width: 1000px) {
  .c-section_infos {
    padding-top: 7.5rem;
  }
  .c-section_infos.-padding {
    padding-top: 35vh;
  }
}
@media (max-width: 999px) {
  .c-section_infos {
    margin-bottom: 1.875rem;
  }
}
.c-section_infos_inner > * {
  opacity: 0;
  transform: translateY(60px);
  transition: opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.c-section_infos_inner.is-inview > * {
  opacity: 1;
  transform: none;
}
.c-section_infos_inner.is-inview > :first-child {
  transition-delay: 0.06s;
  transform: translateY(60px);
}
.c-section_infos_inner.is-inview > :nth-child(2) {
  transition-delay: 0.12s;
  transform: translateY(60px);
}
.c-section_infos_inner.is-inview > :nth-child(3) {
  transition-delay: 0.18s;
  transform: translateY(60px);
}
.c-sections_infos_text {
  margin-top: 1.25rem;
}

.c-section_infos_inner h3 {
  margin: 0rem 0rem;
  font-weight: bold;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.007em;
  line-height: 1.1428571429;
}

.contentCredit {
  color: #fff;
  margin: 0.5rem 0.7rem;
  text-align: left;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: -0.022em;
  line-height: 1.2353641176;
  display: block;
}

.contentDescription {
  margin: 0.25rem 0.7rem;
  text-align: left;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: -0.016em;
  line-height: 1.2857742857;
  display: block;
}

.contentStudio {
  margin: 0.25rem 0;
  text-align: left;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: -0.016em;
  line-height: 1.2857742857;
}

.order-last {
  order: 1;
}
.enter-left {
  transform: translateX(-50%);
  opacity: 1;
}
.enter-right {
  transform: translateX(50%);
  opacity: 1;
}

.c-image {
  opacity: 0;
  transition: opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.c-image.is-inview {
  opacity: 1;
  transition-delay: 0.6s;
}
.c-features-block {
  position: relative;
}
@media (min-width: 1000px) {
  .c-features-block.-margin {
    margin-top: 7.5rem;
  }
}
@media (max-width: 999px) {
  .c-features-block {
    margin: 1.875rem 0;
  }
}
@media (max-width: 699px) {
  .c-features-block {
    margin: 15px 0;
  }
}
.c-features-block_image {
  margin-bottom: -30px;
  margin-top: -30px;
}
.c-features-block_title {
  bottom: 3.75rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  transform: translateY(60px);
  transition: opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.c-features-block_title.is-inview {
  opacity: 1;
  transform: none;
  transition-delay: 0.3s;
}
@media (max-width: 1199px) {
  .c-features-block_title {
    display: none;
  }
}
.c-features-block_bubble {
  color: #d4c9c9;
  font-size: 1.25rem;
  padding: 1.5rem 2.5rem;
  position: absolute;
  right: 20px;
  top: 30%;
  z-index: 1;
}
.c-features-block_bubble.-right {
  right: -30px;
}
.c-features-block_bubble.-left {
  left: -30px;
  right: auto;
}
.c-features-block_bubble.-top {
  top: -20%;
}
.c-features-block_bubble.-bottom {
  bottom: 0;
  top: auto;
}
.c-features-block_bubble:before {
  background-color: #272727;
  border-radius: 50%;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(0);
  transition: transform 0.6s cubic-bezier(0.17, 0.67, 0.3, 1.33);
  z-index: -1;
}
.c-features-block_bubble.is-inview:before {
  transform: scale(1);
  transition-delay: 0.3s;
}
@media (max-width: 1199px) {
  .c-features-block_bubble {
    display: none;
  }
}

@media (min-width: 1000px) {
  .c-summary_text {
    max-width: 320px;
  }
}

@media (max-width: 999px) {
  .summary_list {
    margin-top: 1.875rem;
  }
}

/* Section Styling */
section[data-scroll-section] {
  min-height: 100vh;
  padding: 2rem;
  background-color: rgba(12, 19, 21, 0.702);
}
section[data-scroll-section]:nth-child(odd) {
  background-color: rgba(7, 9, 10, 0.829);
}

/* Features List */
.features-list {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 2rem 0;
}

/* Feature Item */
.feature-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  opacity: 0;
  transform: translateX(0);
  transition: transform 1s ease, opacity 1s ease;
}

/* Enter from Left */
.feature-item.enter-left[data-scroll] {
  transform: translateX(-100%); /* Start off-screen to the left */
  opacity: 1; /* Fade in */
}

/* Enter from Right */
.feature-item.enter-right[data-scroll] {
  transform: translateX(100%); /* Start off-screen to the right */
  opacity: 1; /* Fade in */
}

/* Image Container */
.feature-image-container {
  position: relative;
  max-width: 50%; /* Limit the image size */
  max-height: 33%;
  overflow: auto;
  /*border-radius: 10px;  Optional: Add rounded corners */
  /*box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);  Optional: Add shadow */
}

/* Landscape Images (Width > Height) */
.feature-image-container[data-aspect-ratio="landscape"] {
  max-width: 33%; /* For landscape images */
  max-height: auto; /* Allow height to adjust automatically */
}

/* Portrait Images (Height > Width) */
.feature-image-container[data-aspect-ratio="portrait"] {
  max-height: 30%; /* For portrait images */
  max-width: auto; /* Allow width to adjust automatically */
}

/* Feature Image */
.feature-image {
  width: 100%;
  height: auto; /* Retain aspect ratio */
  object-fit: cover; /* Ensures images fill the container */
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Feature Details Overlay */
.feature-details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  color: white;
  padding: 1rem;
  transform: translateY(100%); /* Start hidden below the image */
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  opacity: 0;
}

/* Hover Effect */
.o-image:hover .feature-details {
  transform: translateY(0); /* Slide up into view */
  opacity: 1;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Professional Text Styling */
.feature-details h3 {
  font-weight: bold;
  margin-bottom: 0.2rem;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.007em;
  line-height: 1.1428571429;
}

.feature-details p {
  margin: 0.25rem 0;
  text-align: left;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: -0.022em;
  line-height: 1.2353641176;
}

.feature-details p .-smaller {
  margin: 0.25rem 0;
  text-align: left;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: -0.016em;
  line-height: 1.2857742857;
}

/* Short Films Section */
.short-films-section {
  padding: 2rem;
}

.short-film-item {
  opacity: 0;
  transition: all 1s ease;
}

.short-film-item.enter-left[data-scroll] {
  transform: translateX(-100%);
  opacity: 1;
}

.short-film-item.enter-right[data-scroll] {
  transform: translateX(100%);
  opacity: 1;
}

/* Workflows Section */
.workflows-section {
  padding: 2rem;
}

.workflow-content {
  opacity: 0;
  transform: translateX(100%);
  transition: all 1s ease;
}

.workflow-content[data-scroll] {
  opacity: 1;
  transform: translateX(0);
}

.navigation-section {
  position: relative;
  height: 100vh;
  overflow: auto;
  z-index: 1;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.8);
}
.navigation-menu {
  position: absolute;
  top: 10%;
  left: 2%;
  z-index: 10;
}

.navigation-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navigation-menu li {
  font-family: "Verdana";
  font-size: 1.2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  color: white;
  transition: color 0.3s ease;
}

.navigation-menu li:hover {
  color: #40e0d0; /* Turquoise hover effect */
}

.navigation-menu a {
  text-decoration: none;
  color: white; /* White text for links */
}

.navigation-menu a:hover {
  color: #40e0d0; /* Turquoise hover effect */
}
@media (min-width: 1200px) {
  a:focus,
  a:hover {
    color: #40e0d0; /* Turquoise hover effect */
  }
}

.o-container {
  -webkit-text-size-adjust: 100%;
  color: #b0c0bc;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  line-height: 1.5;
  font-size: 16px;
  pointer-events: all;
  box-sizing: inherit;
  margin-left: auto;
  margin-right: auto;
  max-width: 95rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.c-summary {
  -webkit-text-size-adjust: 100%;
  color: #b0c0bc;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  line-height: 1.5;
  font-size: 16px;
  pointer-events: all;
  box-sizing: inherit;
  margin: 3.75rem 0 7.5rem;
}

@media (max-width: 699px) {
  .o-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 700px) {
  .o-container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
.o-ratio {
  display: block;
  overflow: auto;
  position: relative;
}
.o-ratio:before {
  content: "";
  display: block;
  padding-bottom: 100%;
  width: 100%;
}
.o-ratio > embed,
.o-ratio > iframe,
.o-ratio > img,
.o-ratio > object,
.o-ratio_content {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.o-layout {
  font-size: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}
.o-layout.-gutter {
  margin-left: -3.75rem;
  margin-bottom: 3rem;
}
.o-layout.-gutter-small {
  margin-left: -1.875rem;
}
.o-layout.-center {
  text-align: center;
}
.o-layout.-right {
  text-align: right;
}
.o-layout.-reverse {
  direction: rtl;
}
.o-layout.-reverse.-flex {
  flex-direction: row-reverse;
}
.o-layout.-flex {
  display: flex;
}
.o-layout.-flex.-top {
  align-items: flex-start;
}
.o-layout.-flex.-middle {
  align-items: center;
}
.o-layout.-flex.-bottom {
  align-items: flex-end;
}
.o-layout.-stretch {
  align-items: stretch;
}
.o-layout_item {
  display: inline-block;
  font-size: 1rem;
  padding-left: 0;
  vertical-align: top;
  width: 100%;
}

.o-layout.-gutter > .o-layout_item {
  padding-left: 3.75rem;
}
.o-layout.-gutter-small > .o-layout_item {
  padding-left: 1.875rem;
}
.o-layout.-middle > .o-layout_item {
  vertical-align: middle;
}
.o-layout.-bottom > .o-layout_item {
  vertical-align: bottom;
}
.o-layout.-center > .o-layout_item,
.o-layout.-reverse > .o-layout_item,
.o-layout.-right > .o-layout_item {
  text-align: left;
}
.o-layout.-reverse > .o-layout_item {
  direction: ltr;
}

.u-2\:1:before {
  padding-bottom: 50%;
}
.u-4\:3:before {
  padding-bottom: 75%;
}
.u-16\:9:before {
  padding-bottom: 56.25%;
}
.u-1\/1 {
  width: 100% !important;
}
.u-1\/2 {
  width: 50% !important;
}
.u-2\/2 {
  width: 100% !important;
}
.u-1\/3 {
  width: 33.33333% !important;
}
.u-2\/3 {
  width: 66.66667% !important;
}
.u-3\/3 {
  width: 100% !important;
}
.u-1\/4 {
  width: 25% !important;
}
.u-2\/4 {
  width: 50% !important;
}
.u-3\/4 {
  width: 75% !important;
}
.u-4\/4 {
  width: 100% !important;
}
.u-1\/5 {
  width: 20% !important;
}
.u-2\/5 {
  width: 40% !important;
}
.u-3\/5 {
  width: 60% !important;
}
.u-4\/5 {
  width: 80% !important;
}
.u-5\/5 {
  width: 100% !important;
}
@media (min-width: 700px) {
  .u-1\/2\@from-small {
    width: 50%;
  }
}
@media (min-width: 800px) {
  .u-1\/3\@from-medium {
    width: 33.33333%;
  }
  .u-1\/2\@from-medium {
    width: 50%;
  }
  .u-2\/5\@from-medium {
    width: 40%;
  }
  .u-3\/5\@from-medium {
    width: 60%;
  }
}
.u-float-left {
  float: left !important;
}
.u-float-right {
  float: right !important;
}
.u-text-center {
  text-align: center !important;
}
.u-text-left {
  text-align: left !important;
}
.u-text-right {
  text-align: right !important;
}
.u-align-baseline {
  vertical-align: baseline !important;
}
.u-align-bottom {
  vertical-align: bottom !important;
}
.u-align-middle {
  vertical-align: middle !important;
}
.u-align-top {
  vertical-align: top !important;
}
.u-vertical-center {
  font-size: 0;
}
.u-vertical-center:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.u-vertical-center > * {
  display: inline-block;
  font-size: 1rem;
  vertical-align: middle;
}
.u-white {
  color: #fff;
}

.u-cyan {
  color: #35c5ad;
}
.u-label {
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
}
.u-icon {
  font-family: Lucida Grande;
  font-size: 1.125rem;
}
.u-text {
  font-size: 0.875rem;
}
.u-clearfix:after {
  clear: both;
  content: "";
  display: block;
}
.u-truncate {
  word-wrap: normal;
  max-width: 100%;
  overflow: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
}
[hidden][aria-hidden="false"] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}
[hidden][aria-hidden="false"]:focus {
  clip: auto;
}
.u-screen-reader-text {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: absolute !important;
  width: 1px;
}
@media not print {
  .u-screen-reader-text\@screen {
    clip: rect(0 0 0 0);
    border: 0;
    height: 1px;
    margin: 0;
    overflow: auto;
    padding: 0;
    position: absolute !important;
    width: 1px;
  }
}
.u-screen-reader-text.-focusable:active,
.u-screen-reader-text.-focusable:focus {
  clip: auto;
  height: auto;
  width: auto;
}

.c-footer_section {
  position: relative;
}
@media (max-width: 1199px) {
  .c-footer_section {
    margin-bottom: 100px;
    padding-bottom: 5%;
  }
}
@media (min-width: 1200px) {
  .c-footer_section {
    margin-bottom: 100px;
  }
}

.c-footer {
  position: relative;
  z-index: 10;
}
@media (max-width: 699px) {
  .c-footer {
    height: 10vh;
    min-height: 100px;
  }
}
@media (min-width: 700px) and (max-width: 999px) {
  .c-footer {
    height: 10vh;
    min-height: 100px;
  }
}
@media (min-width: 1000px) {
  .c-footer {
    height: 10vh;
    min-height: 100px;
  }
}
.c-footer_title {
  padding: 3rem 0;
  perspective: 100px;
  -webkit-perspective: 600px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.c-footer_line,
.c-footer_title {
  top: 0vh;
  left: 0;
  margin: 0;
  position: absolute;
}
.c-footer_line {
  background-color: white;
  right: 0;
  transform: scaleX(0);
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.c-footer_line.is-inview {
  transform: scaleX(1);
}
.c-footer_title_line {
  display: block;
  opacity: 0;
  transform: translateY(100%) rotateX(-80deg);
  transform-origin: center top;
  transform-style: preserve-3d;
  transition: opacity 0s cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 0s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.c-footer_heading {
  padding: 1.875rem 0;
}
.c-footer_heading_label {
  display: block;
  transform: translateY(0px);
  transition: transform 0s cubic-bezier(0.215, 0.61, 0.355, 1);
}
html.is-ready .c-footer_heading_label {
  transform: none;
  transition-duration: 0.6s;
}

.c-footer_video_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  clip-path: inset(30% 0); /* Crop 150px from top and bottom */
  z-index: 0;
  /* animation: slideInFromLeft 1.5s ease-in-out forwards; /* Animate from left */ 
  /* transform: translateX(-100%); */
  opacity: 1;
}
@media (max-width: 999px) {
  .c-damn {
    padding: 0 0 0;
  }
}
@media (min-width: 1000px) {
  .c-damn {
    padding: 0 0 0;
  }
}